.app {
  font-family: "Roboto", Arial, sans-serif;
  display: flex;
  flex-direction: column;
  height: 100vh;
  color: #373534;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.header {
  background-color: #e4e1e0;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 50px;
  flex-direction: column;
}

.header-logos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.header-logos-petit {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-logos-petit img {
  padding-right: 15px;
  padding-left: 15px;
}

.logo1 {
  height: 110px;
  margin-right: 10px;
}
.logo2 {
  height: 150px;
  margin-right: 10px;
}
.logo3 {
  height: 150px;
  margin-right: 10px;
}
.logo4 {
  height: 30px;
  margin-right: 10px;
}
.logo5 {
  height: 120px;
}
.logo7 {
  height: 30px;
}

.header-text {
  text-align: center;
  color: white;
  font-size: 50px;
}

.main-content {
  display: flex;
  flex: 1;
}

.menu {
  background-color: #373534;
  color: #e4e1e0;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  font-size: 20px;
  font-weight: bold;
  width: 20%;
}
.menu a {
  display: block;
  margin: 0;
  padding: 10px 0;
  color: inherit;
  text-decoration: none;
}
.menu p {
  margin: 0;
  padding: 10px 0;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e4e1e0;
}
.content img {
  margin-top: 20px;
  margin-bottom: 0px;
}
ol {
  list-style-type: none;
}
.background {
  background: url(../public/background.jpg) no-repeat center;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #e4e1e0;
  width: 100%;
  height: 450px;
}
.text {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-bottom: 25px;
  margin-top: 25px;
  text-align: center;
  font-size: 18px;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.25) 14px 15px 17px 0px;
}
.quote {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}
.icarda {
  display: flex;
  font-size: 20px;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
}
.icarda img {
  width: 50%;
}
.content h1 {
  font-size: 50px;
  margin-bottom: 5px;
}

.content h2 {
  font-size: 25px;
}

section {
  margin: 20px;
}

footer {
  padding: 10px;
}

img {
  margin: 10px;
}

/* page 2 */

.main-content-ressource {
  display: flex;
  flex-direction: column;
  padding: 20px 70px;
}
.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav a {
  font-size: 30px;
  color: #7dada4;
}
.resource-block {
  margin-bottom: 50px;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.25) 14px 15px 17px 0px;
}
.resource-block h2 {
  padding: 10px 20px;
}
.thumbnails {
  display: flex;
  gap: 10px;
  padding: 20px;
  flex-wrap: wrap;
}

.thumbnail {
  text-align: center;
}

.thumbnail img {
  object-fit: cover;
  width: 100px;
  border-radius: 100px;
}

/* Gallery */

.video-section {
  margin: 20px 0;
  text-align: center;
}

.video-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

.video-container iframe,
.video-container video {
  max-width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 98%;
  padding-left: 30px;
}

.image-container {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  padding: 25px;
}

.gallery-image {
  width: 100%;
  height: auto;
  max-width: 200px; /* Ajustez cette valeur selon vos besoins */
  cursor: pointer;
}

.gallery-image:hover {
  transform: scale(1.05);
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.lightbox-image {
  max-width: 90%;
  max-height: 90%;
}

/* Media queries pour les tablettes */
@media (max-width: 768px) {
  .header {
    padding: 10px 20px;
  }

  .header-text {
    font-size: 40px;
  }

  .menu {
    font-size: 18px;
  }

  .content {
    width: 90%;
    padding: 20px;
    text-align: center;
  }

  .content h1 {
    font-size: 40px;
  }

  .content h2 {
    font-size: 20px;
  }

  /* page 2 */

  .main-content-ressource {
    padding: 20px 30px;
  }

  .nav a {
    font-size: 24px;
  }

  .thumbnails {
    flex-direction: column;
    align-items: center;
  }
}

/* Media queries pour les téléphones */
@media (max-width: 480px) {
  .main-content {
    flex-direction: column;
  }

  .header {
    padding: 10px 0px;
  }

  .logo1 {
    object-fit: contain;
    width: 20%;
    margin-right: 0px;
  }

  .logo3 {
    object-fit: contain;
    width: 15%;
    margin-right: 0px;
  }

  .logo4 {
    object-fit: contain;
    width: 20%;
    margin-right: 0px;
  }
  .logo5 {
    object-fit: contain;
    width: 20%;
    height: 50px;
    margin-right: 0px;
  }
  .logo6 {
    object-fit: contain;
    width: 20%;
    margin-right: 0px;
    margin-left: 0px;
  }

  .logo7 {
    object-fit: contain;
    width: 20%;
    height: 50px;
    margin-right: 0px;
  }
  .header-logos-petit img {
    padding-right: 0px;
  }
  .header-text {
    font-size: 30px;
  }

  .header-logos {
    height: 100px;
  }

  .menu {
    font-size: 16px;
    order: 1;
    width: 100%;
    padding: 20px 0px;
  }
  .menu a {
    padding: 10px;
  }
  .background {
    background-size: 100%;
    display: flex;
    justify-content: center;
  }

  .content {
    width: 95.5%;
    padding: 10px 9px;
    order: 2;
  }
  .content img {
    width: 100%;
  }

  .content h1 {
    font-size: 30px;
    padding: 10px 0px;
  }

  .content h2 {
    font-size: 18px;
  }
  .icarda img {
    width: 50%;
  }
  .text {
    padding: 10px;
  }
  ol {
    list-style-type: disc;
    text-align: justify;
  }

  /* page 2 */

  .main-content-ressource {
    padding: 10px 20px;
  }

  .nav {
    flex-direction: row;
  }

  .nav a {
    font-size: 20px;
  }

  .resource-block {
    margin-bottom: 30px;
  }

  .thumbnails {
    flex-direction: column;
    align-items: center;
  }

  .image-grid {
    justify-content: center;
    padding-left: 0px;
    width: 100%;
  }

  .video-container {
    flex-direction: column; /* Change l'orientation en colonne */
    align-items: center; /* Centre les vidéos */
  }

  .video-container iframe,
  .video-container video {
    width: 90%; /* Laisse un peu d'espace sur les bords */
    height: auto;
  }
}
